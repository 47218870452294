// Translated
// Migrated
<template lang="pug">
.charter-search__window.right-0(
  id="charterWindowGuests"
  ref="charterWindowGuests"
  key="charterWindowGuests"
)
  .charter-search__window-content.padded.overflow-auto
    PackageSearchBarWindowHeader.bg-light-turquoise.pl-4.py-3.mb-3.mx-n3.mx-sm-n4.mt-md-n4.mt-lg-0.mx-lg-0(
      @close="clear"
    )
      template(#default)
        span(data-i18n="charterSelectGuests") {{ $t('charterSelectGuests') }}
      template(#content)
        span.ml-2.badge.badge-md(v-if="noOfPassengers === 9", style="white-space: pre-line;")
          fa(:icon="icons.faInfoCircle")
          span(data-i18n="charterMaxPassengers") {{ $t('charterMaxPassengers') }}

    .row.mt-n3
      .col-12.col-lg-6.col-xl-4.mt-3(
        v-for="(room, index) in rooms",
        :key="`r${index}`"
      )
        .bg-light-turquoise.p-4
          .d-flex.justify-content-between.mb-3
            span.text-lg.font-weight-medium(data-i18n="charterSearchRoomTitle") {{ $t('charterSearchRoomTitle', index + 1) }}
            button.p-0.bg-transparent.text-dark.hover-text-dark-gray(
              v-if="index > 0"
              type="button"
              data-i18n="charterSearchRemoveRoom"
              @click="removeRoom(index)"
            )
              fa(icon="circle-xmark" size="2xl")

          .d-flex.justify-content-between.mb-2
            div
              span.text-base.font-weight-medium(data-i18n="charterSearchAdults") {{ $t('charterSearchAdults') }}
              .charter-search__btn-title(data-i18n="charterSearchAdultsSub") {{ $t('charterSearchAdultsSub') }}
            .d-flex
              NumberButtonInput(
                :value="room.Adults"
                :disable-increase="passengersFull"
                :min="1"
                data-test-adult-number-input
                @on-increase="addAdult(index)"
                @on-decrease="removeAdult(index)"
              )

          .d-flex.justify-content-between
            div
              span.text-base.font-weight-medium(data-i18n="charterSearchChildren") {{ $t('charterSearchChildren') }}
              .charter-search__btn-title(data-i18n="charterSearchChildrenSub") {{ $t('charterSearchChildrenSub') }}
            .d-flex
              NumberButtonInput(
                :value="room.Children"
                :disable-increase="passengersFull"
                :min="0"
                data-test-child-number-input
                @on-increase="addChild(index)"
                @on-decrease="removeChild(index)"
              )

          .border-top.pt-3.mt-3(v-if="room.Children > 0")
            .charter-search__child-age(
              v-for="(_, cindex) in room.ChildAges",
              :key="`c_${index}_${cindex}`"
            )
              label.mb-0(:for="`c_${index}_${cindex}`", data-i18n="charterSearchChildAge") {{ $t('charterSearchChildAge', cindex + 1) }}
              .charter-search__custom-select.charter-search__window-guests__child-age
                select.custom-select(
                  :id="`c_${index}_${cindex}`"
                  :value="room.ChildAges[cindex]"
                  @change="($event) => updateChildAge(index, cindex, +$event.target.value)"
                )
                  option(v-for="r in ageArray", :key="r", :value="r") {{ r }}

      .col-12.col-lg-6.col-xl-4.mt-3
        button.d-flex.align-items-center.text-lg.justify-content-between.bg-light-turquoise.px-4.py-2.w-100(
          type="button"
          :disabled="passengersFull",
          data-test-add-room
          @click="addRoom"
        )
          span.font-weight-medium(data-i18n="charterSearchAddRoom") {{ $t('charterSearchAddRoom') }}
          fa.text-vivid-turquoise(icon="plus-circle" size="2xl")

  .charter-search__btn-search.charter-search__fixed-bottom.d-block.d-lg-none
    ScreenWidthProvider(v-slot="{ isNarrower }")
      .btn-orange-transparent.p-1.legend-text.blink.charter-search__error__childs-ages(
        v-if="(selectChildAges || emptyAgeValues) && isNarrower('lg')",
        data-i18n="charterSelectChildAges"
      ) {{ $t('charterSelectChildAges') }}
    button.rounded-box.btn-orange(type="button", data-i18n="searchButtonText", @click="search") {{ $t('searchButtonText') }}
</template>

<script>
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'

import { evaluatePeoplePerRoom, evaluateRoomsHaveEmptyAgeValues } from '@/stores/utils/charter-packages'

export default defineNuxtComponent({
  setup () {
    const charterWindowGuests = ref(null)

    // defineExpose({ charterWindowGuests })

    return {
      charterWindowGuests
    }
  },

  props: {
    rooms: {
      type: Array,
      default: () => []
    },

    passengersFull: {
      type: Boolean,
      default: false
    },

    selectChildAges: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'add-adult',
    'add-child',
    'add-room',
    'clear',
    'remove-adult',
    'remove-child',
    'remove-room',
    'search',
    'update',
    'update-child-age'
  ],

  data () {
    return {
      icons: {
        faInfoCircle
      },

      ageArray: [...Array(18).keys()],
      emptyAgeValues: false
    }
  },

  computed: {
    displayRooms () {
      return evaluatePeoplePerRoom(this.rooms)
    },

    noOfPassengers () {
      return this.displayRooms.adults + this.displayRooms.children
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      if ((this.rooms || []).length > 0) {
        const rooms = this.rooms.slice()
        rooms.forEach((room, idx) => {
          if (room?.Children > (room?.ChildAges || []).length) {
            rooms[idx] = {
              ...room,
              Children: room.ChildAges.length
            }
            this.update(rooms)
          }
        })
      }
    },

    clear () {
      this.$emit('clear')
    },

    addRoom () {
      const newRoom = {
        Adults: 1,
        Children: 0,
        ChildAges: []
      }
      this.$emit('add-room', newRoom)
      this.update([...this.rooms, newRoom])
    },

    removeRoom (index) {
      this.$emit('remove-room', index)
      this.update(this.rooms.filter((_, i) => i !== index))
    },

    addAdult (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Adults: +this.rooms[index].Adults + 1
      }
      this.$emit('add-adult', index)
      this.update(rooms)
    },

    removeAdult (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Adults: +this.rooms[index].Adults - 1
      }
      this.$emit('remove-adult', index)
      this.update(rooms)
    },

    addChild (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Children: +this.rooms[index].Children + 1,
        ChildAges: [...this.rooms[index].ChildAges, null]
      }

      this.$emit('add-child', index)
      this.update(rooms)
    },

    removeChild (index) {
      const rooms = [...this.rooms]

      rooms[index] = {
        ...rooms[index],
        ChildAges: rooms[index].ChildAges.slice(0, -1),
        Children: +rooms[index].Children - 1
      }

      this.$emit('remove-child', index)
      this.update(rooms)
    },

    updateChildAge (roomIndex, childIndex, age) {
      const rooms = [...this.rooms]

      const ChildAges = rooms[roomIndex].ChildAges
      ChildAges[childIndex] = age
      rooms[roomIndex] = {
        ...rooms[roomIndex],
        ChildAges
      }

      this.$emit('update-child-age', { room: roomIndex, child: childIndex })
      this.update(rooms)
    },

    update (rooms) {
      const { infants, adults } = evaluatePeoplePerRoom(rooms)
      this.emptyAgeValues = evaluateRoomsHaveEmptyAgeValues(rooms)
      this.$emit('update', {
        emptyAgeValues: this.emptyAgeValues,
        tooManyInfants: infants > adults,
        rooms
      })
    },

    search () {
      this.$emit('search')
      this.$emit('clear')
    }
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modules/charter/_charter-forms";
.charter-search__custom-select.charter-search__window-guests__child-age select.custom-select {
  border-right: 1px solid $custom-select-border-color
}
</style>
